// 路由权限
import Vue from 'vue';
import router from './index';
import stort from '../store/index.js';





router.beforeEach((to, from, next) => {

	if (!stort.state.user.token) {

		// 未登录  页面是否需要登录 根据meta标签的   isLogin==true 是需要登录的
		if (to.meta.isLogin === true) {

			next('/login')
		} else {
			window.scrollTo(0, 0); //跳转页面后直接到顶部
			next()
		}
	} else {
		// 用户已经登录  放行
		if (to.path === '/login') {
			if (stort.state.user.token === '') {
				next('/login');
			} else {
				next('/user/account');
			}

		} else {
			next()
		}

	}

	// if (to.meta.showLoading === true) {
	// 	// 如果路由没有匹配到任何路由记录，则等待加载完成
	// 	// setTimeout(() => {
	// 	//   // 使用next()来触发重新进行路由匹配
	// 	//   next();
	// 	// }, 3000); // 可以根据实际情况调整等待时长
	// } else {
	// 	// 如果路由已经匹配到了路由记录，直接进行路由跳转

	// 	next()
	// }

})



//  progressBar.finish() // 隐藏进度条
// })