import {
	setItem,
	getItem,
	removeitem,
	
} from '@/utils/auth';
const user = {
	namespaced: true,
	state: {
		token:getItem('token') || null,
		
	},
	mutations: {
		// 保存token方法
		SET_TOKEN_INFO (state,token) {
			state.token = token
			setItem('token', token)
		},
		REMOVE_TOKEN_INFO (state,token) {
			state.token = token
			removeitem('token')
		},
		
		
	},
	actions: {

	}
}



export default user