import Vue from 'vue'
import App from './App.vue'

Vue.config.productionTip = false

import '@/styles/index.scss'; // 引入index得scss文件

import '../src/assets/css/quanJu.css';//引入全局css文件

import router from './router/index.js';// 引入路由


import './router/permission.js';// 引入路由权限


import 'animate.css';//引入动画库


import './assets/iconfont/iconfont.css';// 阿里适量库


import store from './store';// 引入VUEX


import ElementUI from 'element-ui';// 引入element-ui
import 'element-ui/lib/theme-chalk/index.css';// 引入element-ui
ElementUI.Dialog.props.lockScroll.default = false;


import BaiduMap from 'vue-baidu-map';//引入百度地图


import {scroller} from '@/scroll/scrollTo.js';// 引入vue的滚动 v-scroll-to



import './utils/rem.js';//


// Vue.use(animated)
Vue.use(ElementUI);
Vue.use(BaiduMap, {
  // ak 是在百度地图开发者平台申请的密钥 详见 http://lbsyun.baidu.com/apiconsole/key */
  ak: '2Mo791HIC6OcQfq0SnBVteazHQdYV6Rx'
})
Vue.prototype.$scrollTo = scroller();
// 全局方法挂载
new Vue({
	el: '#app',
	render: h => h(App),
	router,
	store
})