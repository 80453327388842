<template>
	<div id="footer">
		<div class="appheader">
			
			<div>
				<div style="font-size: 0.25rem;font-weight: bold;margin-top: 0.25rem;">公司简介</div>
				<div style="margin-top: 0.225rem;cursor: pointer;"  @click='addAbout'>公司介绍</div>
				<div style="margin-top: 0.225rem;cursor: pointer;"  @click='addAbout'>企业优势</div>
				<div style="margin-top: 0.225rem;cursor: pointer;"  @click='addAbout'>企业合作</div>
			</div>
			<div>
				<div style="font-size: 0.25rem;font-weight: bold;margin-top: 0.25rem;">服务企业</div>
				<div style="margin-top: 0.225rem;">快拍(河北)拍卖有限公司</div>
				<div style="margin-top: 0.225rem;">河北云竞通拍卖有限公司</div>
				<div style="margin-top: 0.225rem;">河北云废通大数据服务有限公司</div>
				
			</div>
			<div>
				<div style="font-size: 0.25rem;font-weight: bold;margin-top: 0.25rem;">联系我们</div>
				<div style="margin-top: 0.225rem;">邮箱:80666@88.com</div>
				<div style="margin-top: 0.225rem;">客服热线:15830000223</div>
				<div style="margin-top: 0.225rem;">公司地址:河北省石家庄裕华区怀特商业广场A座905室</div>
			</div>
			
		</div>
		<div class="border">

		</div>
		<div class="app_footer">
			<span>云竞通服务平台</span>
			<span style="margin-left: 0.225rem;">|</span>
			<span style="margin-left: 0.225rem;">备案号:冀ICP备2024047338号-2</span>
			<span style="margin-left: 0.225rem;">|</span>
			<span style="margin-left: 0.225rem;">技术支持:河北云竞通拍卖有限公司</span>
		</div>
	</div>
</template>

<script>
export default{
	data () {
		return{
			
		}
	},
	methods:{
		addAbout () {
			this.$router.push({
				path:'/about'
			})
		}
	}
}
</script>

<style scoped lang="scss">
	#footer {
		min-width: 18.75rem;
		overflow: hidden;
		min-height: 3.4375rem;
		background-color: #252525;

		.appheader {
			width: 15rem;
			height: 2.375rem;
			
			margin: 0 auto;
			margin-top: 0.1875rem;
			display: flex;
			justify-content: space-between;

			div {
				
				color: #fff;
				font-size: 0.175rem;
				
			}

		}

		.border {
			width: 17.075rem;
			height: 0.0125rem;
			margin: 0.2125rem auto 0.2125rem;
			background: hsla(0, 0%, 91.4%, .2);

		}

		.app_footer {
			width: 15rem;
			height: 0.325rem;
			
			margin: 0 auto;
			text-align: center;
			line-height: 0.325rem;
			color: #fff;
			font-size: 0.175rem;
			font-family: PingFangSC-Regular, PingFang SC;
			letter-spacing: 0.0125rem;
		}

	}
</style>